import { msalInstance } from "vue-msal-browser";

const state = {
  roles: null,
  name: null,
  accountData: null,
};

const mutations = {
  storeRoles(state) {
    state.roles = msalInstance.getAllAccounts()[0].idTokenClaims.roles;
  },

  storeName(state) {
    state.name = msalInstance.getAllAccounts()[0].idTokenClaims.name;
  },

  storeAccountData(state) {
    state.accountData = msalInstance.getAllAccounts()[0];
  },
};

const actions = {
  storeUser(context) {
    context.commit('storeAccountData')
  },

  storeName(context) {
    context.commit('storeName')
  },

  storeRoles(context) {
    context.commit('storeRoles')

  }
}

export default { state, mutations, actions };
