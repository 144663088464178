const state = {
  notifications: [],
}

const getters = {
  notifications: (state) => state.notifications,
}

const actions = {
  async addNotification({ commit }, notification) {
    const allowed_types = [
      'success',
      'warning',
      'danger',
      'info',
      'primary',
      'secondary',
    ]

    if (allowed_types.includes(notification.type)) {
      commit('push_notification', notification)
    } else {
      commit('push_notification', {
        type: 'danger',
        message: 'Alert type unknown',
      })
    }
  },
  async removeNotification({ commit }, notification) {
    commit('remove_notification', notification)
  },
}

const mutations = {
  push_notification(state, notification) {
    state.notifications.push({
      ...notification,
      id: (Math.random().toString(36) + Date.now().toString(36)).substr(2),
    })
  },
  remove_notification(state, notificationToRemove) {
    state.notifications = state.notifications.filter((notification) => {
      return notification.id != notificationToRemove.id
    })
  },
}

export default { state, getters, actions, mutations }
