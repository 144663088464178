<template>
  <div class="dropdown mobile">
    <div class="flex flex-end" @click="openMenu">
      <button class="dropbtn mobile" @click="openMenu">{{menu_data.name}}</button>
    </div>
    <div class="dropdown-content mobile">
      <div class="_text-right">
        <div
          v-for="{ header_name, sub_menus } in menu_data.data"
          :key="header_name"
          :class="`dropdown-${header_name}`"
        >
          <h5 class="_text-center p2">{{ header_name }}</h5>
          
          <div
            v-for="{ name, to, param_name, param, roles } in sub_menus"
            :key="name"
          >
            <router-link
              :v-if="checkroles(roles, msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
              :to="{ name: to, params: { [param_name]: param } }"
              id="fade_success"
              class="_text-center p2"
            >
              {{ name }}
            </router-link>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  props: {
    name: String,
    menu_data: Object,
    index: Number,
  },
  methods: {
    openMenu(event){
      event.stopPropagation() 
      const content = document.getElementsByClassName("dropdown-content mobile")[this.index];
      const container = document.getElementsByClassName("dropdown mobile")[this.index];
      const button = document.getElementsByClassName("dropbtn mobile")[this.index];
      if (content.classList.contains("dropdown-show")) {
        content.classList.remove("dropdown-show")
        button.classList.remove("dropdown-active");
        container.classList.remove("dropdown-active");
      } else {
        content.classList.add("dropdown-show")
        button.classList.add("dropdown-active");
        container.classList.add("dropdown-active");
      }
    },
  },
};
</script>