<template>
  <div class="notification__list">
    <Notification
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
    />
  </div>
</template>

<script>
import Notification from "@/components/toast/Notification";
import { mapGetters } from "vuex";
export default {
  components: {
    Notification,
  },
  computed: mapGetters(["notifications"]),
};
</script>

<style>
.notification__list {
  position: fixed;
  bottom: 0;
  right: 1%;
  width: 325px;
}
</style>
