<template>
  <div id="app">
    <Navbar />
    <img 
      src="../src/assets/bg-kleyn.jpg" 
      style="position: absolute;width:100%;height:100vh;top:0;z-index: -1; -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));"
    >
    <router-view />
    <List />
  </div>
</template>
<script>
import Navbar from "@/components/navbar/Navbar.vue";
import List from "@/components/toast/List";

export default {
  name: "App",
  components: { Navbar, List },
  created() {
    this.msalInstance.authenticateRedirect();
  }
};
</script>

<style lang="sass">
@import "@/sass/main.scss"
</style>
