<template>
  <div class="dropdown">

    <button class="dropbtn"><i class="fa fa-user" /></button>

    <div class="dropdown-content right-profile">
      <div class="flex-row-nowrap">
        <div class="dropdown-Trucks">
          <router-link to="/profile">{{ msalInstance.getAllAccounts()[0].idTokenClaims.name }}</router-link>
          <router-link to="/settings">Instellingen</router-link>
          <a @click="logout">Sign Out</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { msalInstance } from "vue-msal-browser";

export default {
  methods: {
    logout() {
     msalInstance.logoutRedirect();
    }
  }
 
}
</script>

