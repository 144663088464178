<template>
  <div class="dropdown profile">
    <div class="flex flex-end" @click="openMenu">
      <button class="dropbtn profile" @click="openMenu">
        <i class="fa fa-user" />
      </button>
    </div>
    <div class="dropdown-content profile">
      <div class="flex flex-end">
        <div class="dropdown-Trucks">
          <router-link to="/profile">{{ $store.state.user.name }}</router-link>
          <a>Sign Out</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  methods: {
    openMenu(event){
      event.stopPropagation() 
      const content = document.getElementsByClassName("dropdown-content profile")[0];
      const container = document.getElementsByClassName("dropdown profile")[0];
      const button = document.getElementsByClassName("dropbtn profile")[0];
      console.log(content)
      if (content.classList.contains("dropdown-show")) {
        content.classList.remove("dropdown-show");
        button.classList.remove("dropdown-active");
        container.classList.remove("dropdown-active");
      } else {
        content.classList.add("dropdown-show");
        button.classList.add("dropdown-active");
        container.classList.add("dropdown-active");
      }
    }
  },
};
</script>
