const state = {
    search_query: [],
}

const mutations = {
    storeSearchData(state, payload) {
        state.search_query = payload
    }
}

const actions = {
    storeSearchData(context, payload) {
        context.commit('storeSearchData', payload)
    }
}

export default { state, mutations, actions };