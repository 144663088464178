<template>
  <div class="dropdown dropdown-br">
    <button class="dropbtn">{{ menu_data.name }}</button>

    <div class="dropdown-content">
      <div class="flex-row-nowrap">
        <div
          v-for="{ header_name, sub_menus } in menu_data.data"
          :key="header_name"
          :class="`dropdown-${header_name}`"
        >
          <h5 class="_text-center p2">{{ header_name }}</h5>

          <div
            v-for="{ name, to, param_name, param, roles } in sub_menus"
            :key="name"
          >
            <router-link
              v-if="checkroles(roles, msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
              :to="{ name: to, params: { [param_name]: param } }"
              id="fade_success"
            >
              {{ name }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    name: String,
    menu_data: Object,
  }
};
</script>
