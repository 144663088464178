import Vue from "vue";
import Vuex from "vuex";
import toast from "./modules/toast";
import user from "./modules/user"
import search from "./modules/search";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    toast,
    user,
    search
  },
});
