<template>
  <div
    class="alert fade-in show"
    :class="`alert-${notification.type}`"
    role="alert"
  >
    {{ notification.message }}
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
export default {
  data: () => ({
    timeout: null,
  }),
  props: {
    notification: Object,
  },
  methods: {
    removeNotication(notification) {
      this.$store.dispatch("removeNotification", notification);
    },
  },
  created() {
    this.timeout = setTimeout(() => {
      this.removeNotication(this.notification);
    }, 3000);
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
};
</script>