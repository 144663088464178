import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routedata/routes.js";
import nav_items from "./routedata/navitems.js";
import { msalInstance } from "vue-msal-browser";

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  nav_items,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if(to.hash && to.hash.indexOf('#code') != -1){
    router.push('');
  }
  if (msalInstance.getAllAccounts()[0] != null) {
    var isExpired = msalInstance.getAllAccounts()[0].idTokenClaims.exp - Math.floor(Date.now() / 1000) < 0
    if (!isExpired) {
      msalInstance.getSilentToken(msalInstance.getAllAccounts()[0]).then(() => {
        if (msalInstance.getAllAccounts()[0].idTokenClaims.roles.some((r) => to.meta.roles.indexOf(r) >= 0)) {
          next();
        } else {
          router.push({ name: 'noaccess'})
        }
      })
    } else {
      if (msalInstance.getAllAccounts()[0].idTokenClaims.roles.some((r) => to.meta.roles.indexOf(r) >= 0)) {
        next();
      } else {
        router.push({ name: 'noaccess'})
      }
    }
  } else {
    msalInstance.authenticateRedirect().then(() => { next(); })
  }
});

export default router;