//function that handles the API call
export default async function request(endpoint, method = "GET", data = null) {
  const requestOptions = {
    method: method.toUpperCase(),
    body: data ? JSON.stringify(data) : null,
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "KLEYN-APIKEY": process.env.VUE_APP_APIKEY
    },
  };
  //waits till all data is recieved and then returns it in a JSON response
  return await fetch(`${process.env.VUE_APP_API_URL}${endpoint}`, requestOptions)
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    });
}
